const novofies = () => import("@/views/modulos/financeiro/novofies.vue");
const cadbancos = () => import("@/views/modulos/financeiro/cadbancos.vue");
const cadconv = () => import("@/views/modulos/financeiro/cadconvenios.vue");
const remessabanco = () =>
  import("@/views/modulos/financeiro/remessabanco.vue");

const retornoarqremessa = () =>
  import("@/views/modulos/financeiro/retornoarqremessa.vue");

const retornoarqremessaxls = () =>
  import("@/views/modulos/financeiro/retornoarqremessaxls.vue");

const recebimentos = () =>
  import("@/views/modulos/financeiro/recebimentos.vue");

const cadtipos_desconto = () =>
  import("@/views/modulos/financeiro/cadtipos_desconto.vue");

const cadrubrica = () => import("@/views/modulos/financeiro/cadrubrica.vue");
const cadtipobenef = () =>
  import("@/views/modulos/financeiro/cadtipobenef.vue");

const cadagentebenef = () =>
  import("@/views/modulos/financeiro/cadagentebenef.vue");

const listacursos = () =>
  import("@/views/modulos/financeiro/listacursos.vue");

const listacaixageral = () =>
  import("@/views/modulos/financeiro/listacaixageral.vue");

  const contasreceber = () =>
  import("@/views/modulos/financeiro/contasreceber.vue");

  const contasapagar = () =>
  import("@/views/modulos/financeiro/contasapagar.vue");

    const cadcartaorede = () =>
  import("@/views/modulos/financeiro/cadcartaorede.vue");

      const cadcartao = () =>
  import("@/views/modulos/financeiro/cadcartao.vue");

        const cadtipo_credito_aluno = () =>
  import("@/views/modulos/financeiro/cadtipo_credito_aluno.vue");

          const cadfin_fluxocaixa = () =>
  import("@/views/modulos/financeiro/cadfin_fluxocaixa.vue");

            const cadfin_forn = () =>
  import("@/views/modulos/financeiro/cadfin_forn.vue");

              const resumocaixageral = () =>
  import("@/views/modulos/financeiro/resumocaixageral.vue");

  const cadtiponegativacao = () => import("@/views/modulos/financeiro/cadtiponegativacao.vue");
  const listamotivodevcheque = () => import("@/views/modulos/financeiro/listamotivodevcheque.vue");
  const cadcentro_custos = () => import("@/views/modulos/financeiro/cadcentro_custos.vue");
  const cadfin_tipo_doc = () => import("@/views/modulos/financeiro/cadfin_tipo_doc.vue");
  const movbanco = () => import("@/views/modulos/financeiro/movbanco.vue");
  const compensacheques = () => import("@/views/modulos/financeiro/compensacheques.vue");
  const inventarioinadimplencia = () => import("@/views/modulos/financeiro/inventarioinadimplencia.vue");
  const inventariofaturamento = () => import("@/views/modulos/financeiro/inventariofaturamento.vue");
  const cadresponsavelFinanceiro = () => import("@/views/modulos/financeiro/cadresponsavel-financeiro.vue");
  const cadfinGrpfc = () => import("@/views/modulos/financeiro/cadfin-grpfc.vue");
  const cadclientes = () => import("@/views/modulos/financeiro/cadclientes.vue");
  const painelgestorfinanceiro = () => import("@/views/modulos/financeiro/painel-gestor-financeiro.vue");
  const cadvendasclientes = () => import("@/views/modulos/financeiro/cadvendasclientes.vue");
  const cadvendasprodutos = () => import("@/views/modulos/financeiro/cadvendasprodutos.vue");
  const cadvendasvendedor = () => import("@/views/modulos/financeiro/cadvendasvendedor.vue");
  const cadvendasentregador = () => import("@/views/modulos/financeiro/cadvendasentregador.vue");
  const cadvendasorcamento = () => import("@/views/modulos/financeiro/cadvendasorcamento.vue");
  const cadvendascategoriaproduto = () => import("@/views/modulos/financeiro/cadvendascategoriaproduto.vue");
  const cadadmcontratos = () => import("../cadadmcontratos.vue")
  const cadfin_historico_padrao = () => import("../cadfin_historico_padrao.vue")
  const cadfinorigensmovbanco = () => import('../cadfinorigensmovbanco')

export default [
    {
    path: "/cadfinorigensmovbanco/:inc:mod:exc:codsis",
    name:  "cadfinorigensmovbanco",
    props: true,
    component: cadfinorigensmovbanco,
  },

  {
    path: "/cadfin_historico_padrao/:inc:mod:exc:codsis",
    name:  "cadfin_histirico_padrao",
    props: true,
    component: cadfin_historico_padrao,
  },

    {
    path: "/cadadmcontratos/:inc:mod:exc:codsis",
    name:  "cadadmcontratos",
    props: true,
    component: cadadmcontratos,
  },

  {
    path: "/cadvendascategoriaproduto/:inc:mod:exc:codsis",
    name:  "cadvendascategoriaproduto",
    props: true,
    component: cadvendascategoriaproduto,
  },
    {
    path: "/cadvendasorcamento/:inc:mod:exc:codsis",
    name: "cadvendasorcamento",
    props: true,
    component: cadvendasorcamento,
  },
    {
    path: "/cadvendasentregador/:inc:mod:exc:codsis",
    name: "cadvendasentregador",
    props: true,
    component: cadvendasentregador,
  },
    {
    path: "/cadvendasvendedor/:inc:mod:exc:codsis",
    name: "cadvendasvendedor",
    props: true,
    component: cadvendasvendedor,
  },
    {
    path: "/cadvendasprodutos/:inc:mod:exc:codsis",
    name: "cadvendasprodutos",
    props: true,
    component: cadvendasprodutos,
  },
    {
    path: "/cadvendasclientes/:inc:mod:exc:codsis",
    name: "cadvendasclientes",
    props: true,
    component: cadvendasclientes,
  },
    {
    path: "/painelgestorfinanceiro/:inc:mod:exc:codsis",
    name: "painelgestorfinanceiro",
    props: true,
    component: painelgestorfinanceiro  ,
  },
    {
    path: "/cadclientes/:inc:mod:exc:codsis",
    name: "cadclientes",
    props: true,
    component: cadclientes  ,
  },
    {
    path: "/cadfin-grpfc/:inc:mod:exc:codsis",
    name: "cadfingrpfc",
    props: true,
    component: cadfinGrpfc,
  },
    {
    path: "/cadresponsavel-financeiro/:inc:mod:exc:codsis",
    name: "cadresponsavelFinanceiro",
    props: true,
    component: cadresponsavelFinanceiro,
  },
  {
    path: "/inventariofaturamento/:inc:mod:exc:codsis",
    name: "inventariofaturamento",
    props: true,
    component: inventariofaturamento,
  },
  {
    path: "/movbanco/:inc:mod:exc:codsis",
    name: "movbanco",
    props: true,
    component: movbanco,
  },

  {
    path: "/inventarioinadimplencia/:inc:mod:exc:codsis",
    name: "inventarioinadimplencia",
    props: true,
    component: inventarioinadimplencia,
  },

  
   {
    path: "/compensacheques/:inc:mod:exc:codsis",
    name: "compensacheques",
    props: true,
    component: compensacheques,
  },
 
  {
    path: "/cadfin_tipo_doc/:inc:mod:exc:codsis",
    name: "cadfin_tipo_doc",
    props: true,
    component: cadfin_tipo_doc,
  },
  {
    path: "/listamotivodevcheque/:inc:mod:exc:codsis",
    name: "listamotivodevcheque",
    props: true,
    component: listamotivodevcheque,
  },
  {
    path: "/cadcentro_custos/:inc:mod:exc:codsis",
    name: "cadcentro_custos",
    props: true,
    component: cadcentro_custos,
  },
  {
    path: "/resumocaixageral/:inc:mod:exc:codsis",
    name: "resumocaixageral",
    props: true,
    component: resumocaixageral,
  },

    {
    path: "/cadtiponegativacao/:inc:mod:exc:codsis",
    name: "cadtiponegativacao",
    props: true,
    component: cadtiponegativacao,
  },

  {
    path: "/cadfin_forn/:inc:mod:exc:codsis",
    name: "cadfin_forn",
    props: true,
    component: cadfin_forn,
  },
  {
    path: "/cadfin_fluxocaixa/:inc:mod:exc:codsis",
    name: "cadfin_fluxocaixa",
    props: true,
    component: cadfin_fluxocaixa,
  },
  {
    path: "/cadtipo_credito_aluno/:inc:mod:exc:codsis",
    name: "cadtipo_credito_aluno",
    props: true,
    component: cadtipo_credito_aluno,
  },
  {
    path: "/novofies/:inc:mod:exc:codsis",
    name: "novofies",
    props: true,
    component: novofies,
  },
  {
    path: "/cadbancos/:inc:mod:exc:codsis",
    name: "cadbancos",
    props: true,
    component: cadbancos,
  },
  {
    path: "/remessabanco/:inc:mod:exc:codsis",
    name: "remessabanco",
    props: true,
    component: remessabanco,
  },
  {
    path: "/retornoarqremessa/:inc:mod:exc:codsis",
    name: "retornoarqremessa",
    props: true,
    component: retornoarqremessa,
  },
  {
    path: "/retornoarqremessaxls/:inc:mod:exc:codsis",
    name: "retornoarqremessaxls",
    props: true,
    component: retornoarqremessaxls,
  },
  {
    path: "/recebimentos/:inc:mod:exc:codsis",
    name: "recebimentos",
    props: true,
    component: recebimentos,
  },
  {
    path: "/cadconvenios/:inc:mod:exc:codsis",
    name: "cadconvenios",
    props: true,
    component: cadconv,
  },
  {
    path: "/cadtipos_desconto/:inc:mod:exc:codsis",
    name: "cadtipos_desconto",
    props: true,
    component: cadtipos_desconto,
  },
  {
    path: "/cadrubrica/:inc:mod:exc:codsis",
    name: "cadrubrica",
    props: true,
    component: cadrubrica,
  },
  {
    path: "/cadtipobenef/:inc:mod:exc:codsis",
    name: "cadtipobenef",
    props: true,
    component: cadtipobenef,
  },
  {
    path: "/cadagentebenef/:inc:mod:exc:codsis",
    name: "cadagentebenef",
    props: true,
    component: cadagentebenef,
  },
    {
    path: "/listacursos/:inc:mod:exc:codsis",
    name: "listacursos",
    props: true,
    component: listacursos,
  },
    {
    path: "/listacaixageral/:inc:mod:exc:codsis",
    name: "listacaixageral",
    props: true,
    component: listacaixageral,
  },
    {
    path: "/contasreceber/:inc:mod:exc:codsis",
    name: "contasreceber",
    props: true,
    component: contasreceber,
  },
    {
    path: "/contasapagar/:inc:mod:exc:codsis",
    name: "contasapagar",
    props: true,
    component: contasapagar,
  },
    {
    path: "/cadcartaorede/:inc:mod:exc:codsis",
    name: "cadcartaorede",
    props: true,
    component: cadcartaorede,
  },

        {
    path: "/cadcartao/:inc:mod:exc:codsis",
    name: "cadcartao",
    props: true,
    component: cadcartao,
  },

];
