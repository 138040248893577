<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <v-select
          :items="listaatividadesextraclasse"
          item-value="ae_id"
          item-text="ae_descricao"
          label="Atividade"
          outlined
          dense
          v-model="ae_id"
          @change="
            montatexto();
            buscaplanofin();
          "
        />
      </v-col>

      <v-col cols="6">
        <v-select
          :items="planofinanceiro"
          item-value="aev_id"
          item-text="aev_descricao"
          label="Plano financeiro"
          outlined
          dense
          v-model="aev_id"
        />
      </v-col>
    </v-row>

    <v-row dense style="margin-top: -10px">
      <v-col>
        <v-textarea
          rows="4"
          label="Justificativa"
          outlined
          dense
          v-model="just"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row dense style="margin-top: -20px">
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button
          label="Cancelar"
          @click="cancelar"
          style="margin-left: 5px"
        ></Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import axios from "axios";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";

export default {
  name: "segchamada",
  props: ["listaatividadesextraclasse", "aluno", "m_id", "serv_id"],
  data() {
    return {
      just: "",
      nd_id: "",
      aulas: [],
      dataaula: "",
      dataini: "",
      datafim: "",
      arquivos: null,
      planofinanceiro: "",
      ae_id: "",
      aev_id: "",
      plano_fin: "",
    };
  },
  created() {},
  methods: {
    buscaplanofin() {
      http
        .send("", {
          proc: `[FX jaguar cadastros atividade_extra_valores] 'list',null,null,null,${this.ae_id}`,
        })
        .then((res) => {
          this.planofinanceiro = res.dados.data;
          this.aev_id = res.dados.data[0].aev_id;
        });
    },
    montatexto() {
      this.just =
        "O aluno " +
        this.aluno.a_nome +
        ", CGA " +
        this.aluno.cga.trim() +
        ", curso de " +
        this.aluno.s_descricao.trim() +
        ", solicita matrícula na atividade extra classe " +
        this.listaatividadesextraclasse.filter((d) => d.ae_id === this.ae_id)[0]
          .ae_descricao;
    },

    confirmar() {
      console.log("this.ae_id", this.ae_id);
      if (!this.ae_id) {
        this.$toast.error("Favor selecionar a atividade", "", {
          position: "center",
        });
      } else {
        http
          .send("", {
            proc: "[FX jaguar solicitacoes]",
            1: "ativextraclasse",
            2: this.serv_id,
            3: this.$store.state.contexto.BD[0].usr_id,
            4: this.ae_id,
            5: this.aev_id, //"-1", // this.pd_id
            6: "-1", // this.av_id
            7: "",
            8: this.m_id,
            9: this.just,
          })
          .then((res) => {
            this.sol_id = res.dados.data[0].sol_id;
            this.$toast.success("Solicitação incluída com sucesso");
            this.$emit("confirmar", {
              relatorio: res.dados.data[0].relatorio,
              sol_id: res.dados.data[0].sol_id,
            });
          });
      }
    },

    async upload(file) {
      await http
        .send("", {
          proc: "[fx jaguar listas]",
          1: "incanexosol",
          2: this.sol_id,
          3: file.name,
        })
        .then((res) => {
          const formData = new FormData();
          const url =
            Config.baseApiUrlUpload +
            `/upload?destino=${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
              this.aluno.cga
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
                .trim() + "/solicitacoes/"
            }&codigo=${this.sol_id}`;

          formData.append("file", file);

          axios.post(url, formData).then((res) => {
            this.$toast.success("uploaded " + file.name);
          });
        });
    },

    cancelar() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style></style>
